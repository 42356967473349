<template>
  <v-card>
    <v-card-title>
      <div class="d-flex flex-column">
        <span class="title mr-2">{{$t('t.CurrentVersion')}}</span>
        <span class="version">{{version}}</span>
      </div>
    </v-card-title>
    <v-card-subtitle>
      <span class="release">({{release}})</span>
    </v-card-subtitle>
  </v-card>
</template>

<script>

export default {
  components: {
  },
  created () {
  },
  computed: {
  },
  data () {
    return {
    }
  },
  methods: {
  },
  props: {
    version: String,
    release: String
  }
}
</script>

<style lang="stylus" scoped></style>
